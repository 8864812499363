import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import $api from "@/api";
import { useAuth } from "@/store/auth";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { getAuthToken } from "@/api/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

type UserI = {
  id: number;
  chat_id: number;
};

type UserDataContextType = {
  userData: UserI | null;
};

const UserDataContext = createContext<UserDataContextType | undefined>(
  undefined
);

export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData();
  const [searchParams] = useSearchParams();
  const user_token = searchParams.get("user_token");

  const [userData, setUserData] = useState<UserI | null>(null);
  const { token, setToken, authError, setAuthError } = useAuth();

  useEffect(() => {
    if (token) return;
    if (!initData && user_token) {
      setToken(user_token);
      return;
    }
    if (initData) {
      getAuthToken(initData);
    }
  }, []);

  useEffect(() => {
    if (!initData && !user_token) navigate("/unauthorized");
  }, []);

  useEffect(() => {
    if (authError) {
      navigate("/unauthorized");
      setAuthError(false);
    }
  }, [authError]);

  const getData = useCallback(async () => {
    if (!token || !!userData) return;
    try {
      const res = await $api.get("/user/");
      if (res.data[0]) {
        setUserData(res.data[0]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [token, userData]);

  useEffect(() => {
    getData();
  }, [token]);

  return (
    <UserDataContext.Provider value={{ userData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
