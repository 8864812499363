import React, { useEffect, useState } from "react";
import cls from "@/styles/pages/UnauthorizedPage.module.scss";
import { Logo } from "@/components/Logo/Logo";
import { removePreloader } from "@/utils/helpers";

export const UnauthorizedPage = () => {
  const [preloaderRemoved, setPreloaderRemoved] = useState(false);

  useEffect(() => {
    if (!preloaderRemoved) {
      removePreloader();
      setPreloaderRemoved(true);
    }
  }, [preloaderRemoved]);

  return (
    <div className={cls.wrapper}>
      <Logo />
      <div className={cls.text}>Please open this app in Telegram</div>
    </div>
  );
};
